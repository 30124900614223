import { destroyFeature, fetchFeature, fetchFeatures } from '@/api/module/feature'
import { useNotifyErrors, useNotifySuccess, useTableOptions } from '@/composables'
import { reactive, ref, watch } from '@vue/composition-api'
import { debounce } from 'lodash'

const cached = []

export default () => {
  const loading = ref(false)

  const tableColumns = [
    { text: 'ID', value: 'id' },
    { text: 'Key', value: 'key' },

    // { text: 'Name', value: 'name' },
    { text: 'Description', value: 'description' },
    { text: 'Active', value: 'active' },
    {
      text: 'ACTIONS',
      value: 'actions',
      align: 'center',
      sortable: false,
    },
  ]

  const tableItems = ref([])
  const tableTotal = ref(0)

  const options = ref(
    cached[0] || {
      sortBy: ['id'],
      sortDesc: [true],
      itemsPerPage: 15,
    },
  )

  // Filter
  const filters = reactive({
    name: '',
  })

  const loadFeatures = async () => {
    loading.value = true

    try {
      const { data } = await fetchFeatures(useTableOptions(options.value, parseFilters()))

      if (data.status === 'success') {
        tableItems.value = data.data.records
        tableTotal.value = data.data.pagination.total
      }
    } catch (error) {
      console.log(error)
    } finally {
      loading.value = false
    }
  }

  const featuresOptions = ref([])
  const loadFeaturesData = async param => {
    loading.value = true

    try {
      const { data } = await fetchFeatures(param)
      console.log('data', data)
      if (data.status === 'success') {
        featuresOptions.value = data.data.records
      }
    } catch (error) {
      console.log(error)
    } finally {
      loading.value = false
    }
  }

  const feature = ref(null)
  const loadFeature = async id => {
    loading.value = true
    try {
      const { data } = await fetchFeature(id)

      if (data.status === 'success') {
        feature.value = data.data
      }
    } catch (error) {
      console.log(error)
    } finally {
      loading.value = false
    }
  }

  const parseFilters = () =>
    Object.keys(filters)
      .filter(key => filters[key])
      .reduce((a, key) => ({ ...a, [key]: filters[key] }), {})

  const deleteFeature = feature =>
    destroyFeature(feature.id)
      .then(() => {
        useNotifySuccess({ content: 'Delete Success' })
        loadFeatures()
      })
      .catch(useNotifyErrors)

  watch([options], () => {
    loadFeatures()
  })

  watch(
    () => filters.name,
    debounce(() => {
      loadFeatures()
    }, 800),
  )

  return {
    loading,

    tableColumns,
    tableItems,
    tableTotal,

    options,
    filters,

    loadFeatures,
    deleteFeature,

    loadFeaturesData,
    featuresOptions,
    feature,
    loadFeature,
  }
}
